<template>
  <img
    alt="avax logo"
    src="../assets/logo-transparent.png"
    width="100"
    height="100"
  />

  <h1>Avalanche Türkiye</h1>
</template>
